import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  position: relative; /* Position relative for absolute positioning of close button */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.button`
  margin: 0 10px;
  padding: 8px 20px;
  border-radius: 4px;
  background-color: ${(props) => props.bgColor || "transparent"};
  color: ${(props) => props.color || "#333"};
  border: 1px solid ${(props) => props.color || "#3c2f61"};
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.hoverBgColor || "#3c2f61"};
    color: ${(props) => props.hoverColor || "white"};
  }
`;

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onCancel();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onCancel]);

  return (
    <ModalWrapper>
      <ModalContent ref={modalRef}>
        <CloseButton onClick={onCancel}>X</CloseButton>
        <p>{message}</p>
        <ButtonWrapper>
          <Button onClick={onConfirm}>Yes</Button>
          <Button onClick={onCancel}>No</Button>
        </ButtonWrapper>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ConfirmationModal;
