import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const storedEmail = sessionStorage.getItem("email");
  const storedPassword = sessionStorage.getItem("password");

  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") === "true"
  );
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState(storedEmail || "");
  const [password, setPassword] = useState(storedPassword || "");

  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.setItem("email", email);
      sessionStorage.setItem("password", password);
    } else {
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("password");
    }
  }, [isAuthenticated, email, password]);

  const login = (token, user) => {
    setUserData(user);
    setIsAuthenticated(true);
    setError(null);
    sessionStorage.setItem("isAuthenticated", "true");
    sessionStorage.setItem("token", token);
  };

  const logout = async () => {
    try {
      await axios.get("https://dserver.staging.samawati.org/v1/logout", { withCredentials: true });
      setUserData(null);
      setIsAuthenticated(false);
      setError(null);
      sessionStorage.clear();
    } catch (error) {
      console.error("Error during logout:", error);
      setError("An error occurred during logout. Please try again.");
    }
  };
  

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        userData,
        email,
        password,
        setEmail,
        setPassword,
        login,
        logout,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
