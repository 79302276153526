import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Button, message } from "antd";
import styled from "styled-components";
import { useAuth } from "../contexts/AuthContext";
import ConfirmationModal from "./ConfirmationModal";
import { DeleteOutlined } from "@ant-design/icons";

const DashboardStyle = styled.div`
  margin-left: 280px;
  overflow-x: hidden;
`;

const InviteEmployees = () => {
  const [form] = Form.useForm();
  const { email, password } = useAuth();
  const [loading, setLoading] = useState(false);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [inviteToDelete, setInviteToDelete] = useState("");

  const resendInvite = async (pendingEmail) => {
    try {
      setLoading(true);
      const loginResponse = await axios.post(
        "https://api.staging.samawati.org/api/v1/users/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      );

      if (loginResponse.status === 200) {
        const token = loginResponse.data.token;
        const invitationResponse = await axios.post(
          "https://api.staging.samawati.org/api/v1/companies/invites/add",
          {
            email: pendingEmail,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        if (invitationResponse.status === 200) {
          if (
            invitationResponse.data &&
            invitationResponse.data.status === "success"
          ) {
            message.success("Invitation successfully sent.");
          } else if (
            invitationResponse.data &&
            invitationResponse.data.status === "failed" &&
            invitationResponse.data.message === "Invite already done"
          ) {
            message.warning(
              "Invite for this email has not expired yet. Please use a different employee email."
            );
          } else {
            message.error(
              "An error occurred during invitation. Please try again."
            );
          }
        } else {
          message.error(
            "An error occurred during invitation. Please try again."
          );
        }
      } else {
        throw new Error("Token not found in login response");
      }
    } catch (error) {
      console.error("Error resending invitation:", error);
      message.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const sendInvitations = async (values) => {
    try {
      setLoading(true);
      const loginResponse = await axios.post(
        "https://api.staging.samawati.org/api/v1/users/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      );

      if (loginResponse.status === 200) {
        const token = loginResponse.data.token;
        const invitationResponse = await axios.post(
          "https://api.staging.samawati.org/api/v1/companies/invites/add",
          {
            email: values.email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        if (invitationResponse.status === 200) {
          if (
            invitationResponse.data &&
            invitationResponse.data.status === "success"
          ) {
            message.success("Invitation successfully sent.");
            form.resetFields(["email"]);
          } else if (
            invitationResponse.data &&
            invitationResponse.data.status === "failed" &&
            invitationResponse.data.message === "Invite already done"
          ) {
            message.warning(
              "Invite for this email has already been sent.Please use a different employee email."
            );
            form.resetFields(["email"]);
          } else {
            form.resetFields(["email"]);
            message.error(
              "An error occurred during invitation. Please try again."
            );
          }
        } else {
          form.resetFields(["email"]);
          message.error(
            "An error occurred during invitation. Please try again."
          );
        }
      } else {
        throw new Error("Token not found in login response");
      }
    } catch (error) {
      console.error("Error sending invitations:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteCompanyInvite = async (user_email) => {
    setInviteToDelete(user_email);
    setShowConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading(true);
      const loginResponse = await axios.post(
        "https://api.staging.samawati.org/api/v1/users/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      );

      if (loginResponse.status === 200) {
        const token = loginResponse.data.token;
        const deleteResponse = await axios.delete(
          `https://api.staging.samawati.org/api/v1/companies/invites/${inviteToDelete}/remove`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        if (deleteResponse.status === 200) {
          message.success("Invite deleted successfully.");
          setShowConfirmation(false);
          // Refresh pending invites
          fetchPendingInvites();
        } else {
          throw new Error("Error deleting invite.");
        }
      } else {
        throw new Error("Token not found in login response.");
      }
    } catch (error) {
      console.error("Error deleting invite:", error);
      message.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingInvites = async () => {
    try {
      setLoading(true);
      const loginResponse = await axios.post(
        "https://api.staging.samawati.org/api/v1/users/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      );

      if (loginResponse.status === 200) {
        const token = loginResponse.data.token;
        const pendingInvitesResponse = await axios.get(
          "https://api.staging.samawati.org/api/v1/companies/members/pending-invites",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
          }
        );

        if (
          pendingInvitesResponse.status === 200 &&
          pendingInvitesResponse.data
        ) {
          setPendingInvites(pendingInvitesResponse.data);
        } else {
          throw new Error("Error fetching pending invites");
        }
      } else {
        throw new Error("Token not found in login response");
      }
    } catch (error) {
      console.error("Error fetching pending invites:", error);
      message.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingInvites();
    const interval = setInterval(fetchPendingInvites, 30000);
    return () => clearInterval(interval);
  }, []);

  return (
    <DashboardStyle>
      <div
        className="content-container"
        style={{ marginLeft: "30px", padding: "20px" }}
      >
        <br /> <br />
        <div className="card">
          <h4 className="card-header" style={{ fontWeight: "bold" }}>
            Invite employees
          </h4>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <Form form={form} onFinish={sendInvitations} layout="vertical">
                  <div className="row">
                    <div className="col-6">
                      <Form.Item
                        name="email"
                        rules={[{ required: true, type: "email" }]}
                      >
                        <Input placeholder="Email" />
                      </Form.Item>
                    </div>
                    <div className="col-6">
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        style={{ backgroundColor: "#3c2f61", color: "white" }}
                      >
                        Send Invite
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>

          <h4 className="card-header" style={{ fontWeight: "bold" }}>
            Pending Invites
          </h4>
          <div className="card-body">
            <h6>
              {pendingInvites.length > 0 ? (
                <ul style={{ paddingLeft: 0 }}>
                  {pendingInvites.map((invite, index) => (
                    <React.Fragment key={invite.id}>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "5px", // Reduce margin between rows
                          padding: "5px 0", // Reduce padding for list items
                        }}
                      >
                        <div>
                          <i
                            className="fa fa-user"
                            style={{ marginRight: "5px", color: "#3c2f61",fontSize: "22px" }}
                          ></i>
                          {invite.user_email}
                        </div>
                        <div>
                          <a
                            className="btn btn-primary btn-sm"
                            type="button"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              resendInvite(invite.user_email);
                            }}
                            style={{
                              marginLeft: "12px",
                              border: "2px solid #3c2f61",
                              color: "#3c2f61",
                              backgroundColor: "white",
                            }}
                          >
                            Resend invite
                          </a>

                          <a
                            className="btn btn-primary btn-sm"
                            type="button"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              deleteCompanyInvite(invite.user_email);
                            }}
                            style={{
                              marginLeft: "12px",
                              border: "2px solid #3c2f61",
                              color: "#3c2f61",
                              backgroundColor: "white",
                            }}
                          >
                            Cancel invite
                          </a>
                        </div>
                      </li>
                      {index < pendingInvites.length - 1 && (
                        <div
                          className="horizontal-line"
                          style={{
                            borderTop: "1px solid #ccc",
                            margin: "5px 0",
                          }}
                        ></div>
                      )}
                    </React.Fragment>
                  ))}
                </ul>
              ) : (
                <h6>0 pending invites</h6>
              )}
            </h6>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <ConfirmationModal
          message="Are you sure you want to cancel this invite?"
          onConfirm={handleConfirmDelete}
          onCancel={() => setShowConfirmation(false)}
        />
      )}
    </DashboardStyle>
  );
};

export default InviteEmployees;
