import React, { useState, useEffect } from "react";
import "../companylogo.css";
import { useAuth } from "../contexts/AuthContext";
import { NavLink } from "react-router-dom";
import axios from "axios";

const CompanyLogo = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const { email, password, logout } = useAuth();

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    window.addEventListener("click", closeDropdown);

    return () => {
      window.removeEventListener("click", closeDropdown);
    };
  }, []);

  useEffect(() => {
    const loginAndGetCompanyDetails = async () => {
      try {
        const loginResponse = await axios.post(
          "https://api.staging.samawati.org/api/v1/users/login",
          {
            email: email,
            password: password,
          },
          { withCredentials: true }
        );

        if (loginResponse.status === 200) {
          const token = loginResponse.data.token;

          const companyDetailsResponse = await axios.get(
            "https://api.staging.samawati.org/api/v1/companies/details",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
            { withCredentials: true }
          );

          if (companyDetailsResponse.data && companyDetailsResponse.data.name) {
            setCompanyName(companyDetailsResponse.data.name);
          }
        }
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    loginAndGetCompanyDetails();
  }, []);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  };

  const handleLogout = () => {
    logout();
    closeDropdown();
  };

  return (
    <div className="company-logo">
      {companyName ? (
        <div
          className="company-initial"
          style={{
            width: "40px",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#5e95ff",
            color: "#fff",
            borderRadius: "50%",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {companyName.charAt(0).toUpperCase()}
        </div>
      ) : (
        <img
          src="./static/images/faviconv2.png"
          style={{ width: "40px", height: "40px" }}
          alt=""
        />
      )}
      <b>
        <span
          style={{ fontSize: "16px", marginLeft: "5px", cursor: "pointer" }}
          onClick={toggleDropdown}
        >
          {companyName}
          {companyName && " "}
          {companyName && <i className="fa fa-angle-down"></i>}
        </span>
      </b>
      {showDropdown && (
        <div className="dropdown-card" onClick={(e) => e.stopPropagation()}>
          <div className="card">
            <ul>
              <NavLink to="/account">
                <li>Account</li>
              </NavLink>
              <NavLink to="#">
                <li onClick={handleLogout}>Log out</li>
              </NavLink>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompanyLogo;
