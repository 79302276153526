import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AcceptInvitation from "./components/AcceptInvitation";
import Sidenav from "./components/Sidenav";
import Dashboard from "./components/Dashboard";
import Participants from "./components/Participants";
import Login from "./components/Login";
import CompanyLogo from "./components/CompanyLogo";
import InviteEmployees from "./components/InviteEmployees";
import ManagerAccount from "./components/ManagerAccount";
import ManagerSignup from "./components/ManagerSignup";
import SuccessInvite from "./components/SuccessInvite";
import PrivateRoute from "./components/PrivateRoute";
import { useAuth } from "./contexts/AuthContext";
function App() {
  const { isAuthenticated, managerData } = useAuth();
  return (
    <Router>
      <div className="App">
        {isAuthenticated && (
          <>
            <Sidenav />
          </>
        )}

        <Routes>
          <Route path="/signup" element={<ManagerSignup />} />
          <Route path="/login" element={<Login />} />
          <Route path="/invitesuccess" element={<SuccessInvite />} />
          <Route path="/join_company/:token/:email" element={<AcceptInvitation />} />

          <Route
            path="/"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/participants"
            element={
              <PrivateRoute>
                <Participants />
              </PrivateRoute>
            }
          />
          <Route
            path="/account"
            element={
              <PrivateRoute>
                <ManagerAccount/>
              </PrivateRoute>
            }
          />
          <Route
            path="/onboarding"
            element={
              <PrivateRoute>
                <InviteEmployees />
              </PrivateRoute>
            }
          />
        </Routes>
        {isAuthenticated && (
          <>
            <CompanyLogo />
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
