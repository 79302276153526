import React from "react";
import styled from "styled-components";
import "../signup.css";

const BodySignup = styled.div`
  padding: 0px;
  margin: 0px;
  font-family: var(--f-primary);
  overscroll-behavior: none;
`;

const SuccessPage = () => {
  return (
    <BodySignup>
      <div>
        <div className="getQuote_getQuoteForm__1DMlx">
          <div className="container">
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                Your Samawati account has been created successfully.
              </p>
              <p style={{ fontSize: "16px", marginTop: "20px" }}>
                You can now close this page and open the Samawati App to journey
                with us.
              </p>
              <u style={{ color: "blue" }}>
                <a style={{ color: "blue" }} href="https://play.google.com/store/apps/details?id=com.samawatiwellness.samawati">
                  Open Samawati App
                </a>
              </u>
            </div>
          </div>
        </div>
      </div>
    </BodySignup>
  );
};

export default SuccessPage;
