import React from "react";
import { NavLink } from "react-router-dom";

const Sidenav = () => {
  return (
    <div className="sidenav-container">
      <aside
        className="sidenav bg-white navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-4"
        id="sidenav-main"
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
            id="iconSidenav"
          ></i>
          <NavLink to="/">
            <img
              src="./static/images/samawatilogovv2.png"
              style={{ width: "190px" }}
              className="img-fluid"
            />
          </NavLink>
        </div>
        <hr className="horizontal dark mt-0" />
        <div
          className="collapse navbar-collapse w-auto"
          id="sidenav-collapse-main"
        >
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                className="nav-link"
                activeClassName="active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <i
                    className="fa fa-th-large"
                    style={{ fontSize: "24px", color: "#3c2f61" }}
                  ></i>
                </div>
                <span className="nav-link-text ms-1">Dashboard</span>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/participants"
                className="nav-link"
                activeClassName="active"
              >
                <div className="icon icon-shape icon-sm border-radius-md text-center me-2 d-flex align-items-center justify-content-center">
                  <i
                    className="fa fa-github"
                    style={{ fontSize: "29px", color: "#3c2f61" }}
                  ></i>
                </div>
                <span className="nav-link-text ms-1">Participants</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="sidenav-footer mx-3">
          <h6 style={{ color: "#3c2f61" }}>Contact Samawati</h6>
          <br />
          <p style={{ color: "#3c2f61", fontSize: "15px" }}>
            <i
              className="fa fa-envelope-open"
              style={{ fontSize: "20px", color: "#3c2f61" }}
            ></i>
            <span style={{ marginLeft: "15px" }}>hey@samawati.org</span>
            <br />
            <br />
            <i
              className="fa fa-phone"
              style={{ fontSize: "22px", color: "#3c2f61" }}
            ></i>
            <span style={{ marginLeft: "15px" }}>+254101022595</span>
          </p>
        </div>
      </aside>
    </div>
  );
};

export default Sidenav;
