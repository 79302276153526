import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message, Form, Input, Button, Checkbox } from "antd";
import "../signup.css";
import styled from "styled-components";

const BodySignup = styled.body`
  padding: 0px;
  margin: 0px;
  font-family: var(--f-primary);
  overscroll-behavior: none;
`;

const StyledButton = styled(Button)`
  && {
    background-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#3c2f61')};
    border-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#3c2f61')};
    color: ${({ disabled }) => (disabled ? '#d1d1d1' : '#fff')};
    &:hover {
      background-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#5a497a')};
      border-color: ${({ disabled }) => (disabled ? '#a3a0b3' : '#5a497a')};
    }
  }
`;

const AcceptInvitation = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { token, email } = useParams();
  const [loading, setLoading] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ email });
  }, [form, email]);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api.staging.samawati.org/api/v1/invites/accept",
        {
          email: values.email,
          password: values.password,
          first_name: values.first_name,
          last_name: values.last_name,
          token: token,
        },
        { withCredentials: true }
      );

      if (response.data.status === "success") {
        message.success("Invite accepted successfully. Please check your email to get started on Samawati.");
        navigate("/invitesuccess");
        form.resetFields();
      } else {
        message.error("Please use a valid invite link");
        form.resetFields();
      }
    } catch (error) {
      console.error("Error accepting invitation:", error);
      message.error("An error occurred while accepting the invitation");
    } finally {
      setLoading(false);
    }
  };

  return (
    <BodySignup>
      <div>
        <div className="getQuote_getQuoteForm__1DMlx">
          <div className="container">
            <Form form={form} onFinish={onFinish} layout="vertical">
              <div>
                <p style={{ fontSize: "20px" }}>Join Samawati</p>
              </div>
              <Form.Item
                name="first_name"
                rules={[
                  { required: true, message: "Please enter your first name" },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="last_name"
                rules={[
                  { required: true, message: "Please enter your last name" },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input placeholder="Email" disabled />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password" },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                >
                  I agree to the <a href="https://samawati.org/terms-of-use" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Terms of Use</a> and <a href="https://samawati.org/privacy-policy" target="_blank" rel="noopener noreferrer" style={{ color: "blue" }}>Privacy Policy</a>.
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <StyledButton
                  className="btn btn-sm mb-0 w-100 signupbtn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={!termsAccepted}
                >
                  Create Account
                </StyledButton>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </BodySignup>
  );
};

export default AcceptInvitation;
