import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import styled from "styled-components";
import { Modal, Form, Input, Button, Alert, message } from "antd";
import { Link } from "react-router-dom";

const DashboardStyle = styled.div`
  margin-left: 280px;
  overflow-x: hidden;
`;

const ManagerAccount = () => {
  const [loading, setLoading] = useState(true);
  const [managerData, setManagerData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const { email, password } = useAuth();

  const formatActiveSince = (dateString) => {
    const dateObj = new Date(dateString);
    const month = dateObj.toLocaleString("default", { month: "long" });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const loginResponse = await axios.post(
          "https://api.staging.samawati.org/api/v1/users/login",
          {
            email: email,
            password: password,
          },
          { withCredentials: true }
        );

        if (loginResponse.status === 200) {
          const token = loginResponse.data.token;

          const managerResponse = await axios.get(
            "https://api.staging.samawati.org/api/v1/companies/manager/details",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const companyResponse = await axios.get(
            "https://api.staging.samawati.org/api/v1/companies/details",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (
            managerResponse.data.status === "failed" &&
            managerResponse.data.message === "Manager not found for the company"
          ) {
            setManagerData(null);
          } else if (managerResponse.status === 200) {
            const managerData = managerResponse.data;
            setManagerData(managerData);
          }

          if (companyResponse.status === 200) {
            const companyData = companyResponse.data;
            setCompanyData({
              ...companyData,
              company_name: companyData.name,
              active_since: formatActiveSince(companyData.active_since),
              email: email,
              physical_address: companyData.physical_address
            });
          } else {
            message.error("Error fetching company data");
          }
        } else {
          message.error("Error fetching data");
        }
      } catch (error) {
        message.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [email, password]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
    if (managerData) {
      form.setFieldsValue({
        first_name: managerData.first_name,
        last_name: managerData.last_name,
        role: managerData.role,
      });
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const token = (
        await axios.post(
          "https://api.staging.samawati.org/api/v1/users/login",
          { email, password },
          { withCredentials: true }
        )
      ).data.token;

      let response;
      if (managerData) {
        response = await axios.put(
          `https://api.staging.samawati.org/api/v1/companies/manager/profile/update/${managerData.id}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      } else {
        response = await axios.post(
          "https://api.staging.samawati.org/api/v1/companies/manager/profile/create",
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }

      if (response.data.status === "success") {
        message.success(
          managerData
            ? "Manager profile updated successfully"
            : "Manager profile created successfully"
        );

        const managerResponse = await axios.get(
          "https://api.staging.samawati.org/api/v1/companies/manager/details",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (managerResponse.status === 200) {
          const managerData = managerResponse.data;
          setManagerData(managerData);
        } else {
          message.error("Error fetching manager data");
        }
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error submitting form");
    } finally {
      setLoading(false);
      handleCloseModal();
    }
  };

  if (loading) {
    return (
      <div className="text-center spinner">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <DashboardStyle>
      <div className="content-container">
        <br />
        <div className="col">
          <h3>Account</h3>
          <br />
        </div>
        <div>
          <main className="main-content border-radius-lg card container-fluid"  style={{ padding: "23px" }}>
            <div className="row">
              <div className="col-4">
                <div>
                  <h5 style={{ color: "#5e95ff" }}>Company</h5>
                  <br />
                </div>
              </div>
              <div className="col-4" style={{ color: "black" }}>
                <b>
                  <h6>Name</h6>
                </b>
                <p>{companyData?.name || "N/A"}</p>
                <b>
                  <h6>Address</h6>
                </b>
                <p>{companyData?.physical_address || "N/A"}</p>
              </div>
              <div className="col-4" style={{ color: "black" }}>
                <b>
                  <h6>Active Since</h6>
                </b>
                <p>{companyData?.active_since || "N/A"}</p>
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-4">
                <div>
                  <h5 style={{ color: "#5e95ff" }}>Manager</h5>
                  <br />
                </div>
              </div>
              <div className="col-4" style={{ color: "black" }}>
                {managerData ? (
                  <>
                    <b>
                      <h6>Name</h6>
                    </b>
                    <p>
                      {managerData.first_name} {managerData.last_name}
                    </p>
                    <b>
                      <h6>Role</h6>
                    </b>
                    <p>{managerData.role}</p>

                    
                  </>
                ) : (
                  <Button
                    className="btn btn-primary btn-sm"
                    style={{
                      backgroundColor: "#3c2f61",
                      color: "white",
                    }}
                    type="primary"
                    onClick={handleOpenModal}
                  >
                    Add Manager
                  </Button>
                )}
              </div>
              <div className="col-4" style={{ color: "black" }}>
                <b>
                  <h6>Email</h6>
                </b>
                <p>{companyData?.email || "N/A"}</p>
                  
                {managerData && (
              <div>
                 <Link
                      to="#"
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        display: "inline-block",
                        marginLeft: "10px",
                        marginTop: "29px"
                      }}
                      onClick={handleOpenModal}
                    >
                      Update Manager
                    </Link>
              </div>
            )}
              </div>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="col-4">
                <div>
                  <h5 style={{ color: "#5e95ff" }}>Account</h5>
                  <br />
                </div>
              </div>
              <div className="col-4" style={{ color: "#3c2f61" }}>
                <b>
                  <h6>Change Password</h6>
                </b>
              </div>
              <div className="col-4" style={{ color: "#3c2f61" }}>
                <b>
                  <h6>Delete Account</h6>
                </b>
              </div>
            </div>
          
          </main>
        </div>
      </div>

      <Modal
        title={
          managerData ? "Update Manager Profile" : "Create Manager Profile"
        }
        visible={isModalOpen}
        onCancel={handleCloseModal}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="first_name"
            label="First Name"
            rules={[
              { required: true, message: "Please input the first name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last Name"
            rules={[{ required: true, message: "Please input the last name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="role"
            label="Role"
            rules={[{ required: true, message: "Please input the role!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              className="btn btn-primary btn-sm"
              style={{
                backgroundColor: "#3c2f61",
                color: "white",
              }}
              type="primary"
              htmlType="submit"
            >
              {managerData ? "Update" : "Create"}
            </Button>
            <Button
              className="btn btn-sm"
              style={{ marginLeft: "8px" }}
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </DashboardStyle>
  );
};

export default ManagerAccount;
