import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip
);

const LineChart = ({ data }) => {
  // Reverse the arrays returned by map functions
  const reversedLabels = data.map((entry) => {
    const date = new Date(entry.month);
    return date.toLocaleString("default", { month: "long" });
  }).reverse();

  const reversedData = data.map((entry) => entry.count).reverse();

  const formattedData = {
    labels: reversedLabels,
    datasets: [
      {
        data: reversedData,
        fill: true,
        tension: 0.4,
        backgroundColor: "rgba(94, 149, 255, 0.2)",
        borderColor: "rgba(94, 149, 255, 1)",
        borderWidth: 2,
        pointBackgroundColor: "rgba(94, 149, 255, 1)",
        pointRadius: 4,
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          font: {
            weight: "bold",
            size: 14,
          },
        },
        ticks: {
          color: "#5E95FF",
          font: {
            weight: "bold",
            size: 12,
          },
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        title: {
          display: true,
          text: "Count of ID (Activity Logs)",
          font: {
            weight: "bold",
            size: 14,
          },
        },
        ticks: {
          color: "#5E95FF",
          font: {
            weight: "bold",
            size: 12,
          },
          stepSize: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += Math.round(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <div>
      <br></br>
      <br></br>
      <div style={{ width: "530px", height: "500px" }}>
        <Line data={formattedData} options={chartOptions} />
      </div>
    </div>
  );
};

export default LineChart;
